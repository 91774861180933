import { useRef, useState, useEffect } from "react";
import logo from "../Assets/jadbery.svg";
import logoWhite from "../Assets/jadbery-white.svg";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { useWindowSize } from "react-use";

const Home = () => {
  const { width } = useWindowSize();
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  const text = useRef(null);
  const text1 = useRef(null);
  const box = useRef(null);
  const button = useRef(null);
  const container = useRef(null);
  const ellipse = useRef([]);
  const elParent = useRef(null);
  const [col, setCol] = useState(0);

  const openWhatsapp = () => {
    let link =
      width < 500
        ? "https://api.whatsapp.com/send"
        : "https://web.whatsapp.com/send";
    const url = `${link}/?phone=7306858170`;
    window.open(url, "_blank");
  };

  const calculateCols = () => {
    if (container.current) {
      setCol(Math.round(container.current.clientWidth / 100));
    }
  };

  useEffect(() => {
    calculateCols();
    window.addEventListener("resize", calculateCols);
    return () => {
      window.removeEventListener("resize", calculateCols);
    };
  }, []);

  useGSAP(() => {
    const tl = gsap.timeline();
    tl.from(text.current, { x: -1000, duration: 1.5, yoyo: true }, 0);
    tl.from(text1.current, { x: 1000, duration: 1.5 }, 0.5);
    tl.from(box.current, { width: 0, duration: 1 }, 0);
    tl.from(button.current, { y: 300, duration: 1 }, 0.5);
  }, []);

  useGSAP(() => {
    const tl1 = gsap.timeline({ defaults: { ease: "none", duration: 4 } });
    ellipse.current.forEach((circle, index) => {
      tl1.to(
        circle,
        { y: window.innerHeight, repeat: -1, yoyo: true },
        index * (4 / ellipse.current.length)
      );
    });
  }, [elParent.current]);

  // Mouse move effect for spotlight
  useEffect(() => {
    const handleMouseMove = (event) => {
      const imgRect = imageRef.current.getBoundingClientRect();
      const relativeX = event.clientX - imgRect.left;
      const relativeY = event.clientY - imgRect.top;
      setMousePos({ x: relativeX, y: relativeY });
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      id="home"
      ref={container}
      className="bg-black w-full h-screen relative overflow-hidden"
    >
      <div className="w-full h-full md:w-1/2 md:blur-[10vw] bg-gradient-to-b from-[rgba(255,255,255,0.2)] to-transparent blur-3xl absolute -top-64 left-1/2 -translate-x-1/2 rounded-b-full"></div>
      <div
        ref={elParent}
        className="absolute flex mx-6 md:mx-16 inset-0 justify-around"
      >
        {[...Array(col)].map((_, i) => (
          <div
            key={i}
            className="h-screen w-px bg-gradient-to-b from-charcoal to-transparent relative"
          >
            <div
              ref={(el) => (ellipse.current[i] = el)}
              className="h-2.5 w-2.5 bg-charcoal relative left-1/2 -translate-x-1/2 rounded-full"
            ></div>
          </div>
        ))}
      </div>
      <img
        src={logo}
        alt="logo"
        className="absolute z-10 px-6 md:px-16 lg:w-3/4 w-full left-1/2 top-1/4 -translate-x-1/2"
      />
      <img
        ref={imageRef}
        src={logoWhite}
        alt="logo"
        className="absolute px-6 md:px-16 lg:w-3/4 w-full z-20 left-1/2 top-1/4 -translate-x-1/2 filter brightness-[0.25] transition-all duration-700"
        style={{
          maskImage: `url(#spotlight-mask)`,
          WebkitMaskImage: `url(#spotlight-mask)`,
        }}
      />
      <svg width="0" height="0">
        <defs>
          <filter id="blur-circle" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="35" />
          </filter>
          <mask id="spotlight-mask">
            <rect width="100%" height="100%" fill="black" />
            <circle
              cx={mousePos.x}
              cy={mousePos.y}
              r="100"
              fill="white"
              filter="url(#blur-circle)"
            />
          </mask>
        </defs>
      </svg>

      <div className="absolute left-0 bottom-5 md:bottom-10 lg:bottom-15 px-6 md:px-16 lg:px-40 font-montserrat font-extralight text-4xl lg:text-6xl text-white flex flex-col items-start w-full">
        <p ref={text}>
          from
          <br />
          imagination
        </p>
        <div className="w-full flex flex-col justify-between gap-6 md:gap-0 md:flex-row md:items-end">
          <p className="text-right md:order-1" ref={text1}>
            to
            <br />
            innovation
          </p>
          <div className="border-b-2 w-max h-max overflow-hidden" ref={box}>
            <button
              className="text-xl md:text-2xl lg:text-4xl text-cherry text-nowrap"
              ref={button}
              onClick={openWhatsapp}
            >
              Get in touch
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
