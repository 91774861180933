import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import { useGSAP } from "@gsap/react";

const About = () => {
  const textRef = useRef(null);
  const triggerRef = useRef(null);

  useGSAP(() => {
    const splitText = SplitText.create(textRef.current, { type: "words" });
    splitText.words.forEach((word) => {
      let l = SplitText.create(word, { type: "chars" });
      gsap.from(l.chars, {
        scrollTrigger: {
          trigger: triggerRef.current,
          scrub: 1,
          start: "10% center",
          end: "center center",
        },
        opacity: 0.2,
        stagger: 0.2,
      });
    });
  }, []);

  return (
    <div
      id="about"
      ref={triggerRef}
      className="flex justify-center items-center h-screen bg-black"
    >
      <div
        ref={textRef}
        className="text-center w-5/6 lg:w-1/2 text-white flex flex-col gap-8"
      >
        <p className="text-4xl font-light">We bring digital visions to life.</p>

        <p className="text-4xl font-light">
          Our team builds innovative web and app solutions for exceptional
          <span className="text-cherry"> user experiences !</span>
        </p>
      </div>
    </div>
  );
};

export default About;
