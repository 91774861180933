import "./App.css";
import "./index.css";

import About from "./Components/About";
import Client from "./Components/Client";
import Reach from "./Components/Reach";
import Home from "./Components/Home";
import Service from "./Components/Service";
import Footer from "./Components/Footer";
import Nav from "./Components/Nav";
import Works from "./Components/Works";
import { gsap } from "gsap";
import { useEffect, useState } from "react";
import { IoMenuOutline } from "react-icons/io5";
import Preloader from "./Components/Preloader";

import { useGSAP } from "@gsap/react";
import { GSDevTools } from "gsap/GSDevTools";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

gsap.registerPlugin(
  useGSAP,
  ScrollToPlugin,
  ScrollTrigger,
  SplitText,
  GSDevTools,
  ScrollSmoother,
  MorphSVGPlugin
);

// GSDevTools.create()

function App() {
  const [navOpen, setNavOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state for preloader

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Hide preloader after 100%
    }, 1500); // Adjust this timeout as per preloader speed

    return () => clearTimeout(timer);
  }, []);

  useGSAP(() => {
    ScrollSmoother.create({
      wrapper: "#smooth-wrapper",
      content: "#smooth-content",
      smooth: 1,
      effects: true,
    });
  }, []);

  return (
    <div className="App overflow-hidden w-screen">
      {isLoading ? null : (
        <>
          {navOpen && (
            <div className="fixed inset-0 w-full h-screen z-50">
              <Nav setNavOpen={setNavOpen} />
            </div>
          )}
          {!navOpen && (
            <IoMenuOutline
              id="ham-menu"
              onClick={() => setNavOpen(true)}
              className="fixed z-50 right-8 top-5 text-white text-6xl custom-drop-shadow cursor-pointer"
            />
          )}
        </>
      )}

      <div id="smooth-wrapper">
        <div id="smooth-content">
          {isLoading ? (
            <Preloader /> // Show preloader if loading is true
          ) : (
            <>
              {/* Once preloader is done, show the main content */}
              <Home />
              <Client />
              <About />
              <Service />
              <Works />
              <Reach />
              <Footer />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
