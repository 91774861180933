import React, { useRef } from "react";
import logo from "../Assets/logo-cherry.svg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const FooterLink = ({ anim }) => {
  const footerLinks = useRef(null);
  const handleClick = (hash) => {
    gsap.to(window, {
      duration: 1,
      scrollTo: hash,
      ease: "Power1.easeInOut",
    });
  };
  useGSAP(() => {
    let mm = gsap.matchMedia();
    mm.add("(min-width: 800px)", () => {
      anim(-250, footerLinks.current);
    });
    mm.add("(max-width: 799px)", () => {
      anim(-100, footerLinks.current);
    });
  });

  return (
    <div>
      <div className="flex justify-between lg:flex-col items-center bg-erBlack p-8">
        <div className="block lg:hidden">
          <img src={logo} alt="Logo" className="h-16 w-auto" />
        </div>

        <div ref={footerLinks} className="flex lg:flex-col font-light gap-8 ">
          <div className="flex flex-col lg:text-2xl gap-2  md:flex-row justify-between text-cherry">
            <button onClick={() => handleClick("#home")}>home</button>
            <button onClick={() => handleClick("#about")}>about</button>
            <button onClick={() => handleClick("#works")}>portfolio</button>
            <button onClick={() => handleClick("#service")}>services</button>
          </div>
          <div className="flex flex-col justify-center">
            <div className="hidden lg:block">
              <p className="text-5xl font-jad text-cherry m-16 pt-16">
                JADBERY
              </p>
            </div>

            <div className="flex flex-col gap-2 lg:text-xl md:flex-row justify-between  text-cherry">
            <a href="//facebook.com/jadbery.digital" target="_blank">
            facebook
          </a>
          <a href="//instagram.com/jadbery.digital" target="_blank">
            instagram
          </a>
          <a href="#" target="_blank">
            linkedIn
          </a>
          <a href="#" target="_blank">
            x / twitter
          </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterLink;
