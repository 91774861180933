import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useRef } from "react";
import { useWindowSize } from 'react-use';
const Reach = () => {
  const reachRef = useRef(null);
  const arrowIconRef = useRef(null);
  const { width, height } = useWindowSize()

  const openWhatsapp = () => {
    let link = "https://web.whatsapp.com/send"
    if (width < 500) {
      link = "https://api.whatsapp.com/send"
    }
    let url = `${link}/?phone=7306858170`;
    window.open(url, "_blank");
  }
  useGSAP(() => {
    gsap.from(
      reachRef.current,
      {
        scrollTrigger: {
          trigger: reachRef.current,
          start: "top bottom",
          end: "bottom bottom",
          scrub: true,
        },
        x: -150,
        duration: 1,
      },
      0
    );



    gsap.from(
      arrowIconRef.current,
      {
        scrollTrigger: {
          trigger: arrowIconRef.current,
          start: "top bottom",
          end: "bottom bottom",
          scrub: true,
        },
        x: 75,
        duration: 1,
      },
      0
    );


  });

  return (
    <div id="contact" >
      <div className="flex items-center justify-between bg-richBlack p-12 md:px-36">
        <div ref={reachRef} >
          <p className="text-white text-lg font-light md:text-5xl md:py-2">
            Let us hear from <span className="text-cherry">you</span>
          </p>
          <p className="text-white text-sm font-light md:text-4xl md:py-2">
            Reach <span className="text-cherry">Us</span>
          </p>
        </div>
        <button onClick={openWhatsapp} ref={arrowIconRef} className="flex items-center justify-center bg-white text-cherry rounded-md hover:bg-cherry hover:text-white transition-colors duration-300 p-3 md:p-6">
          <svg
            id="arrow-svg"
            className="arrow-icon h-3 w-3 md:h-12 md:w-12"
            width="13"
            height="11"
            viewBox="0 0 13 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="arrow-path"
              d="M7.32801 10.3135C7.28191 10.2674 7.24534 10.2127 7.22038 10.1525C7.19543 10.0923 7.18259 10.0278 7.18259 9.96265C7.18259 9.89749 7.19543 9.83296 7.22038 9.77277C7.24534 9.71257 7.28191 9.65789 7.32801 9.61183L10.9446 5.99586L1.2328 5.99586C1.10129 5.99586 0.975173 5.94362 0.882184 5.85063C0.789194 5.75764 0.736954 5.63152 0.736954 5.50002C0.736954 5.36851 0.789194 5.24239 0.882184 5.1494C0.975173 5.05641 1.10129 5.00417 1.2328 5.00417L10.9446 5.00417L7.32801 1.3882C7.23497 1.29515 7.1827 1.16896 7.1827 1.03738C7.1827 0.905803 7.23497 0.779612 7.32801 0.686571C7.42105 0.59353 7.54725 0.54126 7.67883 0.54126C7.81041 0.54126 7.9366 0.59353 8.02964 0.686571L12.4923 5.1492C12.5384 5.19525 12.5749 5.24994 12.5999 5.31013C12.6249 5.37033 12.6377 5.43485 12.6377 5.50002C12.6377 5.56518 12.6249 5.6297 12.5999 5.6899C12.5749 5.75009 12.5384 5.80478 12.4923 5.85083L8.02964 10.3135C7.98359 10.3596 7.9289 10.3961 7.86871 10.4211C7.80851 10.446 7.74399 10.4589 7.67883 10.4589C7.61366 10.4589 7.54914 10.446 7.48895 10.4211C7.42875 10.3961 7.37406 10.3596 7.32801 10.3135Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Reach;
