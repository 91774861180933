import React, { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import ServiceCard from "./ServiceCard";
import service1 from "../Assets/service 1.png";
import service2 from "../Assets/service 2.png";
import service3 from "../Assets/service 3.png";
import service4 from "../Assets/service 4.png";

const Service = () => {
  const serviceHeading = useRef(null);
  const serviceCards = useRef(null);
  const containerRef = useRef(null);

  useGSAP(() => {
    let mm = gsap.matchMedia();

    // desktop only
    mm.add("(min-width: 800px)", () => {
      gsap.from(
        serviceHeading.current,
        {
          scrollTrigger: {
            trigger: containerRef.current,
            start: "top bottom",
            end: "bottom 90%",
            scrub: true,
          },
          x: -250,
          duration: 1,
        },
        0
      );

      gsap.from(
        serviceCards.current,
        {
          scrollTrigger: {
            trigger: containerRef.current,
            start: "top bottom",
            end: "bottom 90%",
            scrub: true,
          },
          x: 150,
          duration: 1,
        },
        0
      );
    });
    mm.add("(max-width: 799px)", () => {
      gsap.from(
        serviceHeading.current,
        {
          scrollTrigger: {
            trigger: serviceHeading.current,
            start: "top bottom",
            end: "bottom 90%",
            scrub: true,
          },
          x: -250,
          duration: 1,
        },
        0
      );
    });
  });

  return (
    <div
      id="service"
      ref={containerRef}
      className="pt-10 flex flex-col lg:flex-row gap-8 items-start lg:items-center"
    >
      {/* Expertise Title Section */}
      <div
        ref={serviceHeading}
        className="flex flex-col w-full lg:w-1/3 mx-7 h-max lg:-rotate-90"
      >
        {/* Rotation only for large screens */}
        <h3 className="font-montserrat font-light text-4xl lg:text-6xl text-charcoal whitespace-nowrap">
          <span className="text-cherry">Our</span> Expertise.
        </h3>
        <p className="font-montserrat font-light py-4 text-2xl">
          The things we are <span className="text-cherry">good at.</span>
        </p>
      </div>
      {/* Service Cards Section */}
      <div ref={serviceCards} className="w-full flex flex-col lg:flex-row">
        <div className="flex flex-col lg:w-full">
          <ServiceCard name="Android Development" image={service1} />
          <ServiceCard name="Web App Development" image={service2} />
        </div>
        <div className="flex flex-col  lg:w-full lg:mt-5">
          <ServiceCard name="Creative Website" image={service3} />
          <ServiceCard name="Branding" image={service4} />
        </div>
      </div>
    </div>
  );
};

export default Service;
