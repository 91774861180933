import React, { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const FooterCredit = ({anim}) => {
  const footerCreds = useRef(null);
  useGSAP(()=>{
    let mm = gsap.matchMedia();
    mm.add("(min-width: 800px)", () => {
      anim(150, footerCreds.current)
    });
  })

  return (
    <div className="w-full flex h-full">
      <footer className="bg-black relative text-white py-8 w-full h-full flex flex-col justify-between">
        <div className="absolute flex inset-0 justify-around">
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
        </div>

        <div
          ref={footerCreds}
          className="flex relative z-10 justify-between lg:flex-col lg:items-start items-center p-8"
        >
          <div className="text-left  lg:text-left lg:m-4">
            <p className="text-sm lg:text-2xl">Write Us</p>
            <a
              href="mailto:info@jadbery.com"
              className="text-cherry text-base lg:text-3xl lg:text-left"
            >
              info@jadbery.com
            </a>
          </div>

          <div className="text-right lg:text-left lg:m-4">
            <p className="text-sm lg:text-2xl">Reach Us</p>
            <a
              href="tel:+919567604529"
              className="text-cherry text-base lg:text-3xl lg:text-left"
            >
              +91 9567 604 529
            </a>
          </div>
        </div>

        <hr className="my-4 lg:w-full w-1/2 border-richBlack mx-auto" />

        <div className="text-center text-gray-400 text-sm">
          <p>
            Crafted with care ❤️
            <span className="text-cherry font-medium "> Jadbery</span> 2024
          </p>
        </div>
      </footer>
    </div>
  );
};

export default FooterCredit;
