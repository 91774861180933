import React, { useState, useEffect } from "react";
import logo from "../Assets/jadbery.svg";

const Preloader = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          return prev + 1;
        }
        clearInterval(interval);
        return prev;
      });
    }, 50); // Adjust speed here
  }, []);

  return (
    <div className="h-screen bg-white flex flex-col justify-center items-center relative">
      <div className="absolute flex inset-0 justify-around opacity-5">
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
        <div className=" w-px bg-gradient-to-b from-black to-transparent relative"></div>
      </div>
      <div className="w-1/2 z-10 lg:w-1/4 opacity-10 m-4">
        <img src={logo} alt="jadbery"></img>
      </div>
      <div
        className="h-1 w-64 bg-cherry mb-4"
        style={{
          width: `${progress}%`,
          transition: "width 0.5s ease-in-out",
        }}
      ></div>

      <div className="absolute right-10 bottom-10 text-cherry lg:text-8xl md:text-6xl font-light">
        {progress}%
      </div>
    </div>
  );
};

export default Preloader;
