import React from "react";

const NavCredit = () => {
  return (
    <div className="w-full flex h-full lg:h-screen">
      <div className="bg-black relative text-white py-8 w-full h-full flex flex-col justify-between">
        <div className="absolute flex inset-0 justify-around">
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
          <div className=" w-px bg-gradient-to-b from-charcoal to-transparent relative"></div>
        </div>

        <div className="z-10 w-full h-full lg:h-screen flex items-center">
          <div className="flex flex-col justify-center items-start m-16">
            <div className="text-left mb-4">
              <p className="text-cherry text-xl lg:text-2xl">Write Us</p>
              <a
                href="mailto:info@jadbery.com"
                className="text-white text-2xl  lg:text-3xl lg:text-left"
              >
                info@jadbery.com
              </a>
            </div>

            <div className="text-left">
              <p className="text-xl text-cherry lg:text-2xl">Reach Us</p>
              <a
                href="tel:+919567604529"
                className="text-white text-2xl lg:text-3xl lg:text-left"
              >
                +91 9567 604 529
              </a>
            </div>
          </div>
        </div>

        <div className="relative z-50 mx-4 lg:mx-8 flex flex-row gap-2 font-medium lg:text-xl justify-between">
          <a href="//facebook.com/jadbery.digital" target="_blank">
            facebook
          </a>
          <a href="//instagram.com/jadbery.digital" target="_blank">
            instagram
          </a>
          <a href="#" target="_blank">
            linkedIn
          </a>
          <a href="#" target="_blank">
            x / twitter
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavCredit;
