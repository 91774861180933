import React from "react";
import NavLink from "./NavLink";
import NavCredit from "./NavCredit";
import { TfiClose } from "react-icons/tfi";

const Nav = ({ setNavOpen }) => {
  return (
    <div className="flex h-screen w-screen flex-col justify-center lg:flex-row">
      <div onClick={()=>setNavOpen(false)} className=" absolute z-50 right-8 top-5">
        <TfiClose
          className="cursor-pointer text-black lg:text-white text-6xl"
        />
      </div>
      <div className="w-full lg:w-2/5 flex-1 bg-white">
        <NavLink setNavOpen={setNavOpen} />
      </div>
      <div className="w-full lg:w-3/5 items-end flex-1 bg-black">
        <NavCredit />
      </div>
    </div>
  );
};

export default Nav;
