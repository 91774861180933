import { useEffect, useRef, useState } from "react";
import { SlArrowRight } from "react-icons/sl";

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";


const ServiceCard = ({ name, image }) => {
  const box = useRef(null);
  const containerRef = useRef(null);
  const [row, setRow] = useState(0);
  const [col, setCol] = useState(0);
  const calculateRowsAndCols = () => {
    if (box.current) {
      setRow(Math.round(box.current.clientWidth / 100));
      setCol(Math.round(box.current.clientHeight / 100));
    }
  };

  useGSAP(() => {
    let mm = gsap.matchMedia();
    mm.add("(max-width: 799px)", () => {
      gsap.from(containerRef.current,{
        x: 200,
        opacity: 0,
        scrollTrigger: {
            trigger: containerRef.current,
            start: "top 90%",
            end: "bottom bottom",
            scrub: true,
        }
      });
    });
  });

  useEffect(() => {
    calculateRowsAndCols();

    window.addEventListener("resize", calculateRowsAndCols);

    return () => {
      window.removeEventListener("resize", calculateRowsAndCols);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="w-full px-3 relative mb-9 lg:h-64 lg:hover:scale-105 lg:transition-all lg:duration-200"
    >
      <div className="absolute h-full w-full px-3 z-10 inset-0">
        <div className="h-full absolute flex px-3 flex-row w-full inset-0 justify-evenly">
          {[...Array(row)].map((e, i) => (
            <div key={i} className="h-full w-[0.5px] bg-charcoal"></div>
          ))}
        </div>
        <div className="h-full absolute flex px-3 flex-col w-full inset-0 justify-evenly">
          {[...Array(col)].map((e, i) => (
            <div key={i} className="w-full h-[0.5px] bg-charcoal"></div>
          ))}
        </div>

        <div
          ref={box}
          className="relative rounded-md bg-gradient-to-tr sm:from-[#00000081] from-black from-40% to-transparent w-full h-full z-10"
        ></div>
      </div>
      <div className="flex relative flex-col h-full sm:flex-row justify-between px-6 py-8 gap-9 overflow-hidden  bg-black text-white font-montserrat rounded-md">
        <div className="h-20 w-20 blur-3xl z-20 right-0 top-0 sm:right-auto sm:left-0 absolute bg-cherry "></div>
        <img
          src={image}
          alt={name}
          className="w-56 sm:w-72 lg:w-2/6 z-30 relative self-center"
        />
        <div className="flex flex-col items-end gap-9 justify-between sm:pt-16 sm:pr-4">
          <h3 className="text-2xl sm:font-normal text-right  z-30 relative w-min">
            {name}
          </h3>
          <div className="flex font-light  text-md z-30 relative items-center border-b-[1px] pb-1">
            <p>Explore</p>
            <SlArrowRight className="text-base" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
