import React, { useRef, useEffect } from "react";

import fina from "../Assets/fina.svg";
import evas from "../Assets/evas.svg";
import diet from "../Assets/diet.svg";

import { gsap } from "gsap";

const logos = [
  { src: evas, alt: "evas" },
  { src: diet, alt: "diet+" },
  { src: fina, alt: "finance" },
  { src: evas, alt: "evas" },
  { src: diet, alt: "diet+" },
  { src: fina, alt: "finance" },
  { src: evas, alt: "evas" },
  { src: diet, alt: "diet+" },
  { src: fina, alt: "finance" },
  { src: evas, alt: "evas" },
  { src: diet, alt: "diet+" },
  { src: fina, alt: "finance" },
];

const Client = () => {
  const carouselRef = useRef({current: {children: []}});
  const containerRef = useRef(null);

  useEffect(() => {
    setTimeout(()=>{
      const totalWidth = Array.from(carouselRef.current.children).reduce(
        (acc, child) => acc + child.offsetWidth,
        0
      );

      // Duplicate the logos for seamless scrolling
      gsap.set(carouselRef.current, {
        width: totalWidth * 2,
      });

      gsap.to(carouselRef.current, {
        x: `-${totalWidth}px`,
        duration: 20,
        ease: "none",
        repeat: -1,
        onRepeat: function () {
          gsap.set(carouselRef.current, { x: 0 });
        },
      });
    },2000)
  }, []);

  return (
    <div className="bg-black">
      <div ref={containerRef} className="overflow-hidden">
        <div
          ref={carouselRef}
          className="carousel flex justify-start items-center md:gap-8 space-x-8 bg-black py-4"
        >
          {/* Render logos twice for seamless scrolling */}
          {logos.concat(logos).map((logo, index) => (
            <img
              key={index}
              src={logo.src}
              alt={logo.alt}
              className="h-16 w-max opacity-50 hover:opacity-100 filter brightness-0 invert"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Client;
