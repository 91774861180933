import { useRef } from "react";
import FooterLink from "./FooterLink";
import FooterCredit from "./FooterCredit";
import gsap from "gsap";

const Footer = () => {
  const containerRef = useRef(null);
  const anim = (x, from) => {
    gsap.from(from, {
      x: x,
      duration: 1,
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top bottom",
        end: "bottom bottom",
        scrub: true,
      },
    });
  };
  return (
    <div ref={containerRef} className="flex flex-col lg:flex-row">
      <div className="w-full lg:w-4/6">
        <FooterLink anim={anim} />
      </div>
      <div className="w-full lg:w-2/6">
        <FooterCredit anim={anim} />
      </div>
    </div>
  );
};

export default Footer;
