import "./PaycodPolicy.scss"


function AutoChatPolicy() {
    return (
        <div>
            <div className="policy-page">
                <h1 className="policy-page__title">AutoChat - Privacy Policy</h1>
                <p className="policy-page__content">
                    This Privacy Policy explains how AutoChat (the "App") collects, uses, and shares information when you use it on the Meta platform. We are committed to protecting your privacy and empowering you to control your information.
                    <h1 className="policy-page__title">Information We Collect</h1>
                    <ul className="policy-page__content">
                        <li>
                            <span className="bold-text">Content Access</span>: With your permission, the App accesses the content of comments, messages, and posts that you specify. This is necessary for the App to function as you direct.
                        </li>
                        <li>
                            <span className="bold-text">Message Content</span>: The App accesses the content of messages you send and receive to fulfill your instructions, such as sending replies or messages on your behalf.
                        </li>
                        <li>
                            <span className="bold-text">Aggregate Data</span>: We collect anonymized data about the total number of messages sent through the App. This helps us understand usage patterns and improve the App.
                        </li>
                    </ul>
                    <h1 className="policy-page__title">How We Use Information</h1>
                    <ul className="policy-page__content">
                        <li>
                            <span className="bold-text">Core Functionality</span>: We use the information we collect to enable the core features of the App, such as reading comments, replying to messages, and sending messages on your behalf.
                        </li>
                        <li>
                            <span className="bold-text">Analytics</span>: We use aggregate data to analyze usage trends and improve the App's performance and features.
                        </li>
                    </ul>
                    <h1 className="policy-page__title">Information Sharing</h1>
                    <ul className="policy-page__content">
                        <li>
                            <p>
                                <span className="bold-text">No Third-Party Sharing</span>: We do not sell or share your personal information with any third parties for advertising or other purposes.
                            </p>
                        </li>
                        <li>
                            <span className="bold-text">User-Defined Content</span>: The content of replies and messages sent on your behalf is determined entirely by you.
                        </li>
                    </ul>
                    <h1 className="policy-page__title">Data Storage and Security</h1>
                    <ul className="policy-page__content">
                        <li>
                            <span className="bold-text">Minimal Storage</span>: We store only the information necessary to provide the App's functionality. This includes the total message count and the user-defined replies and message templates.
                        </li>
                        <li>
                            <span className="bold-text">Data Security</span>: We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction.
                        </li>
                    </ul>
                    <h1 className="policy-page__title">Your Choices</h1>
                    <ul className="policy-page__content">
                        <li>
                            <span className="bold-text">Content Access</span>: You can control which comments, messages, and posts the App can access.
                        </li>
                        <li>
                            <span className="bold-text">Data Deletion</span>: You can request to delete your user-defined replies and message templates at any time.
                        </li>
                    </ul>
                    <h1 className="policy-page__title">Changes</h1>
                    We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                    <h1 className="policy-page__title">Contact Us</h1>
                    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us at mail@jadbery.com.
                    <p>Effective Date: 22/05/2024</p>
                </p>
            </div>

        </div>
    )
}

export default AutoChatPolicy