import React from "react";
import { gsap } from "gsap";

const NavLink = ({ setNavOpen }) => {
  const handleClick = (hash) => {
    setNavOpen(false);
    gsap.to(window, {
      duration: 1,
      scrollTo: hash,
      ease: "Power1.easeInOut",
    });
  };
  return (
    <div className="w-full flex items-center h-full justify-center">
      <div className="flex flex-col text-3xl lg:text-5xl items-start m-8 gap-8 text-black">
        <button
          onClick={() => handleClick("#home")}
          className="hover:text-cherry"
        >
          Home
        </button>
        <button
          onClick={() => handleClick("#about")}
          className="hover:text-cherry"
        >
          About
        </button>
        <button
          onClick={() => handleClick("#works")}
          className="hover:text-cherry"
        >
          Portfolio
        </button>
        <button
          onClick={() => handleClick("#service")}
          className="hover:text-cherry"
        >
          Services
        </button>
        <button
          onClick={() => handleClick("#contact")}
          className="hover:text-cherry"
        >
          Contact
        </button>
      </div>
    </div>
  );
};

export default NavLink;
