import React, { useRef } from "react";
import mockup from "../Assets/mockup.png";
import evas from "../Assets/eva-mockup.png";
import diet from "../Assets/diet-mockup.png";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const worksData = [
  {
    title: "Finance",
    service: "Android Application",
    tech: ["Figma", "Android Native", "Firebase SSO"],
    image: mockup,
    href: "#",
  },
  {
    title: "Eva's Wardrobe",
    service: "E-Commerce",
    tech: ["Shopify", "GraphQL", "Polaris"],
    image: evas,
    href: "https://evaswardrobe.com/",
  },
  {
    title: "Diet+",
    service: "Android Application",
    tech: ["Figma", "Android Native", "Firebase SSO"],
    image: diet,
    href: "#",
  },
];

const Works = () => {
  const workRef = useRef([]);
  const workConRef = useRef([]);

  useGSAP(() => {
    let mm = gsap.matchMedia();
    mm.add("(min-width: 800px)", () => {
      for (let work of workRef.current) {
        gsap.from(work, {
          scrollTrigger: {
            trigger: work,
            start: "top bottom",
            end: "bottom bottom",
            scrub: true,
          },
          x: 1000,
        });
      }
      for (let work of workConRef.current) {
        gsap.from(work, {
          x: -1000,
          scrollTrigger: {
            trigger: work,
            start: "top bottom",
            end: "bottom bottom",
            scrub: true,
          },
        });
      }
    });
    mm.add("(max-width: 799px)", () => {
      for (let work of workRef.current) {
        gsap.from(work, {
          scrollTrigger: {
            trigger: work,
            start: "top bottom",
            end: "bottom 80%",
            scrub: true,
          },
          x: 250,
        });
      }
      for (let work of workConRef.current) {
        gsap.from(work, {
          x: -250,
          scrollTrigger: {
            trigger: work,
            start: "top bottom",
            end: "bottom 80%",
            scrub: true,
          },
        });
      }
    });
  });

  return (
    <div
      id="works"
      className="h-auto bg-black flex justify-center items-center"
    >
      <div className="w-full m-8">
        <h3 className="font-montserrat flex justify-end font-light text-4xl py-8 lg:p-8 lg:text-6xl text-charcoal">
          Featured<span className="text-cherry">&nbsp;Works.</span>
        </h3>
        <div>
          {worksData.map((work, index) => (
            <div key={index}>
              <div
                className="overflow-hidden flex flex-col w-11/12 lg:w-5/6 lg:flex-row justify-between bg-gray-200 p-8 rounded-lg shadow-sm mx-auto my-4"
              >
                <div
                  ref={(el) => (workConRef.current[index] = el)}
                  className="relative w-full lg:w-1/2 flex justify-center items-center"
                >
                  <div className="absolute top-0 left-0 z-50">
                    <a
                      href={work.href}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white text-black px-4 py-2 rounded-lg shadow-sm  hover:bg-gray-100 focus:outline-none"
                    >
                      Explore
                    </a>
                  </div>
                  <img
                    src={work.image}
                    alt={`${work.title} Mockup`}
                    className="gap-8 lg:gap-16 lg:w-auto transform hover:scale-105 transition-transform duration-300"
                  />
                </div>

                <div
                  ref={(el) => (workRef.current[index] = el)}
                  className="w-full lg:h-512 lg:w-2/6 mt-8 lg:mt-0 lg:ml-8 bg-white rounded-lg shadow-sm flex flex-col justify-between p-8"
                >
                  <div>
                    <h2 className="text-4xl font-normal text-black mb-4">
                      {work.title}
                    </h2>
                    <p className="text-xl text-black mb-8">{work.service}</p>
                  </div>
                  <ul className="text-cherry text-base">
                    {work.tech.map((tech, i) => (
                      <li key={i}>{tech}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Works;
